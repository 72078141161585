/** @jsx jsx */
import styled from "styled-components"
import { jsx, Styled } from "theme-ui"
import { Grid } from "@theme-ui/components"
import { useStaticQuery, Link, graphql, navigate } from "gatsby"
import Layout from "@components/Layouts"
import { rem } from "@src/theme"
import Pill from "@components/Pill"
import CardResults from "@components/CardResults"
import { TAG_OPTIONS } from "@content/forms"
import Spacer from "@components/Spacers"
import SEO from "@components/seo"
import { getSlug } from "@helpers/utils/getSlug"
import { Themed } from "@theme-ui/mdx"
import TwoColumnSection, {
  Block as ColumnBlock,
  ImageBlock as ColumnImageBlock,
} from "@components/TwoColumnSection"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import CalloutStack from "@components/CalloutStack"

const calloutStackData = {
  bgColor: "white",
  title: "Explore More",
  blocks: [
    {
      text: "Identity and Stats",
      link: "/identity-and-stats",
      color: "pink",
      bg: "purple",
    },
    {
      text: "People and Stories",
      link: "/people-and-stories",
      bg: "turquoise",
      color: "darkPurple",
    },
    {
      text: "Allyship",
      link: "/allyship",
      bg: "darkPurple",
      color: "white",
    },
  ],
}

const IdentityAndStats = props => {
  const data = useStaticQuery(
    graphql`
      query {
        articles: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { glob: "**/posts/identity-and-stats/*.md" }
          }
          sort: { order: DESC, fields: frontmatter___date }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                title
                slug
                cardPreview
                image
                tags
              }
            }
          }
        }

        og: markdownRemark(
          fileAbsolutePath: { glob: "**/pages/identity-and-stats/index.md" }
        ) {
          frontmatter {
            ogTitle
            ogimage
            ogdescription
            header {
              ...Header
            }
            tags
          }
        }
      }
    `
  )

  const { header, tags, ogTitle, ogimage, ogdescription } = data.og.frontmatter

  return (
    <Layout>
      <SEO
        title={ogTitle || header?.heading}
        pathname={props.location.pathname}
        image={ogimage || header?.image}
        description={ogdescription || header?.subtext}
      />
      <div sx={{ bg: "pink" }}>
        <ColumnBlock
          styles={{
            bg: "purple",
            color: "white",
          }}
        >
          <CTAHeadingBlock
            heading={header.heading}
            body={header.subtext}
            buttonLabel={header.ctaButton?.label}
            buttonLink={header.ctaButton?.link}
            centerText
          />
          <div
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "1rem",
              pb: "2rem",
            }}
          >
            <Pill
              largeVariant
              color="white"
              onClick={() => navigate("/identity-and-stats")}
            >
              Identity and Stats
            </Pill>
            <Pill
              largeVariant
              color="white"
              onClick={() => navigate("/people-and-stories")}
            >
              People and Stories
            </Pill>
            <Pill
              largeVariant
              color="white"
              onClick={() => navigate("/allyship")}
            >
              Allyship
            </Pill>
          </div>
        </ColumnBlock>
        <CardResults
          linkPrefix={`identity-and-stats`}
          items={data.articles.edges.map(article => ({
            ...article,
            slug: getSlug(article),
            preview: article.node.frontmatter.cardPreview,
          }))}
          heading="What do you want to read about?"
          TAG_OPTIONS={tags.map(tag => ({ label: tag }))}
          {...props}
        />
      </div>
    </Layout>
  )
}

const HeaderGridArticleItem = styled.div`
  background-position: center;
  position: relative;
  z-index: 1;
  display: flex;

  .header-grid__article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    h2 {
      margin-top: 0;
    }
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.6;
  z-index: -1;
`

export default IdentityAndStats
